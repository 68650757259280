@tailwind base;
@tailwind components;
@tailwind utilities;

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ecb390;
  color:#fcf8e8;
  text-align: center;
  font-size: 50px;
  margin: 10px;
}
.App-body {
  background-color: #d4e2d4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: #df7861;
  margin: 10px;
}
.App-intro {
  font-size: 25px;
  text-align: center;
  padding-right: 150px;
}

.App-link {
  font-size: 20px;
  color:white;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 5px 5px 5px 5px;
  background-image: url('naverlogo.png');
  background-size: 100% 100%;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.definition {
  font-size: 25px;
}
.App-button {
  color: #df7861;
  background-color: #fcf8e8;
  padding-left: 3px;
  padding-right: 3px;
  border-radius:2px 2px 2px 2px;
}

.uebtn {
  color: #df7861;
  font-size: 20px;
  background-color: #fcf8e8;
  padding-left: 3px;
  padding-right: 3px;
  border-radius:2px 2px 2px 2px;
}

.btn {
  color: #df7861;
  font-size: 20px;
  border: 2px dashed #fcf8e8;
  padding-left: 3px;
  padding-right: 3px;
	display:inline-block;
	border-radius:10px 10px 0px 0px;
	background:#fcf8e8;
}

.btn:hover {
  color:white;
  background-color: #df7861;
}

.resultbox {
  border: 2px dashed #fcf8e8;
}

.syllable {
  font-size: 25px;
}

.hrstyle {
  border-top: 2px dashed #df7861;
}